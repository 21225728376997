import React from "react"
import css from "./AnimationText.module.scss"
export default function AnimationText(props) {
  const getTheMessage = rawString => {
    let convert = []
    let delay = 0

    Array.from(rawString).forEach((key, index) => {
      if (key === " ") {
        convert.push(" ")
      } else if (key.match(/\r?\n/g)) {
        convert.push(<br key={`br-` + index} />)
        delay += 0.5
      } else {
        delay += 0.032

        if (key.match(/(「)/g)) {
          delay += 0.34
        }

        convert.push(
          <span
            className={css.animationChar}
            style={{ animationDelay: delay + "s" }}
            key={`spna-` + index}
          >
            {key}
          </span>
        )

        if (key.match(/(」|、|。)/g)) {
          delay += 0.39
        }
      }
    })
    return <p className={css.animationText}>{convert}</p>
  }

  return getTheMessage(props.text)
}
