import React, { useEffect, useState } from "react"
import GlobalNavi from "../components/GlobalNavi"
import { Box } from "@material-ui/core"
import LinkButton from "../components/LinkButton"
import BpHr from "../components/BpHr"
import css from "./index.module.scss"
import TopSlider from "../components/TopSlider"
import TopTalkSlider from "../components/TopTalkSlider"
import SEO from "../components/seo"
import AnimationText from "../components/AnimationText"
import { Link } from "gatsby"

export default function Home() {
  const [titlePlayed, setTitlePlayed] = useState(false)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setTitlePlayed(true)
    }, 1750)

    return () => {
      clearTimeout(timeout)
    }
  }, [titlePlayed, setTitlePlayed])

  return (
    <div>
      <SEO />
      <GlobalNavi />

      <div className={`l-wrapper`}>
        <div className={css.entrance}>
          <Box className={css.box4x3}>
            <div className={css.box4x3__inner}>
              <Box className={css.sliderBox}>
                <Box className={css.sliderBox__Title} role="banner">
                  <h1 className={`fontLvH3`}>
                    <AnimationText
                      text={`ヨコハマ・パラトリエンナーレ2020\nBOOK PROJECT「そのうち届くラブレター」`}
                    />
                  </h1>
                </Box>
                <Box className={css.sliderBox__Slider}>
                  {titlePlayed ? (
                    <TopSlider play={true} />
                  ) : (
                    <TopSlider play={false} />
                  )}
                </Box>
              </Box>
            </div>
          </Box>
        </div>

        <div className={`l-wrapper__inner`}>
          <Box mb={{ xs: `20px`, sm: `40px` }}>
            <p className={`fontLvBody`}>
              ヨコハマ・パラトリエンナーレ
              2020美術展「そのうち届くラブレター」は、ブックとして制作されました。
              この特設ウェブサイトでは、つぎの４つのことができます。
            </p>
            <ol className={`fontLvBody list-style-inside`}>
              <li>ブック（PDF）の無料ダウンロード</li>
              <li>映像作品と音声作品の鑑賞</li>
              <li>
                篠原ともえさんと西岡克浩さん（第1部）、ロバート
                キャンベルさんと中川美枝子さん（第2部）、中川美枝子さんと華雪さん（第3部）が、ブックで紹介したすべての作品を鑑賞する「おしゃべり対話鑑賞」視聴
              </li>
            </ol>
            <Box mt={{ xs: `20px`, sm: `30px` }}>
              <p className={`color--gray`}>
                <span className={`fontLvBody`}>視聴期限</span>
                <br />
                <span className={`fontLvBody`}>
                  Me, My Mouth and I:
                  2020年11月24日まで（コア期間の1週間のみ公開）
                </span>
                <br />
                <span className={`fontLvBody`}>
                  リ・ビンユアンの映像作品: 2021年3月31日まで
                </span>
                <br />
                <span className={`fontLvBody`}>
                  おしゃべり対話鑑賞（第1部）: 2021年11月17日まで
                </span>
                <br />
                <span className={`fontLvBodyS`}>
                  ＊その他の動画も予告なく配信を終了させていただくことがあります。予めご了承ください。
                </span>
                <br />
              </p>
            </Box>
          </Box>
        </div>
      </div>

      <Box className={css.oshaberi}>
        <div className={`l-wrapper`}>
          <div className={`l-grid l-grid--xs is-align-center`}>
            <div className={`l-grid__8 l-grid--xs__12`}>
              <div>
                <h2 className={`fontLvH3`}>
                  おしゃべり対話鑑賞{" "}
                  <Link to={`/talk/`} className={`fontLvBodyS`}>
                    チャプターごとに観る
                  </Link>
                </h2>
              </div>
              <div>
                <LinkButton
                  color={`green`}
                  to={"/talk/tomoe-shinohara-katuhiro-nishioka"}
                  disabled={true}
                >
                  篠原ともえさん × 西岡克浩さん <br/>
                  <small>＊2021年11月17日をもっておしゃべり対話鑑賞第1部の公開は終了しました</small>
                </LinkButton>
                <LinkButton
                  color={`green`}
                  to={"/talk/robert-campbell-mieko-nakagawa"}
                >
                  ロバート キャンベルさん × 中川美枝子さん
                </LinkButton>
                <LinkButton color={`green`} to={"/talk/mieko-nakagawa-kasetsu"}>
                  中川美枝子さん × 華雪さん
                </LinkButton>
              </div>
            </div>
            <div className={`l-grid__4 l-grid--xs__12`}>
              <div className={css.oshaberi__image}>
                <TopTalkSlider />
              </div>
            </div>
          </div>
        </div>
      </Box>

      <div className={`l-wrapper`}>
        <BpHr type={`dot`} />
        <div className={`l-wrapper__inner`}>
          <Box>
            <LinkButton color={`green`} to={"/works/"}>
              ブックで紹介したすべての作品
            </LinkButton>
            <LinkButton color={`green`} to={"/works/jess-thom/"}>
              ジェス・トムのドキュメンタリー映画『Me, My Mouth and I』
              <div>
                <div  className="fontLvBodyS" style={{ lineHeight: "1.5em", marginTop:"0.5em" }}>
                  ＊
                  11月24日をもって映画の公開は終了しましたが、ジェス・トムを迎えて行われた11月21日のトークイベントの映像をご覧頂けます。
                </div>
              </div>
            </LinkButton>
            <LinkButton color={`green`} to={"/profile/"}>
              作家とナビゲーターの紹介
            </LinkButton>
          </Box>
          <BpHr type={`dot`} />
          <Box>
            <LinkButton color={`green`} to={"/about/"}>
              このプロジェクトについて
            </LinkButton>
            <LinkButton color={`green`} to={"/bookproject/"}>
              ブックを手に入れる
            </LinkButton>
          </Box>
        </div>
      </div>
    </div>
  )
}
