import React from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./TopTalkSlider.module.scss"
import HgwSlider from "./HgwSlider"
const TopTalkSlider = () => (
  <StaticQuery
    query={query}
    render={data => (
      <div className={css.base}>
        <HgwSlider type={`type2`}>
          {data.allFile.nodes.map((image, key) => {
            // console.log(image.childImageSharp.fixed)
            return (
              <div key={key}>
                <img src={image.childImageSharp.fixed.src} alt={""} />
              </div>
            )
          })}
        </HgwSlider>
      </div>
    )}
  />
)

export default TopTalkSlider

const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/talk/top.+jpg/" } }) {
      nodes {
        childImageSharp {
          fixed(width: 640, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
